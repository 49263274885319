import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wallet: null,
    eligible: false,
    credentials: null,
    modalText: null
  },
  mutations: {
    wallet: function(state, data) {
      state.wallet = data
    },
    eligible: function(state, eligible) {
      state.eligible = eligible
    },
    credentials: function(state, data) {
      state.credentials = data
    },
    modalText: function(state, modalText) {
      state.modalText = modalText
    }
  }
})