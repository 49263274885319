<template>
  <div class="telegram-box p-8 relative">
    <div class="platform-logo"><brand-telegram-icon class="text-white" /></div>
    <p class="text-xl break-words mt-4 mb-7">{{ text }}</p>

    <div v-if="loggedIn">
      <button @click="logout()" class="button w-full mt-2">
        Log out <logout-icon class="ml-1.5 text-telegram" />
      </button>
      <button
        class="button w-full mt-2"
        v-if="$store.state.credentials.linked_telegram"
        @click="unlink()"
      >
        Unlink Account <unlink-icon class="ml-1.5 text-telegram" />
      </button>
      <button
        class="button w-full mt-2"
        v-if="!$store.state.credentials.linked_telegram"
        @click="link()"
      >
        Link Account <link-icon class="ml-1.5 text-telegram" />
      </button>
      <a
        :href="telegramLink"
        class="button w-full mt-2"
        v-if="$store.state.credentials.linked_telegram"
        >Join Telegram <brand-telegram-icon class="ml-1.5 text-telegram"
      /></a>
    </div>
    <div v-else>
      <div class="telegram-wrapper rounded border border-transparent relative overflow-hidden">
        <button class="button w-full border-none invisible">
          Log In <chevron-up-right-icon class="ml-1.5 text-telegram" />
        </button>
        <button
          class="
            button
            w-full
            pointer-events-none
            absolute
            top-0
            left-0
            z-10
            border-none
          "
        >
          Log In <chevron-up-right-icon class="ml-1.5 text-telegram" />
        </button>
        <div id="tg-button" class="absolute w-full top-0 left-0 z-0"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Config from "@/config";
import { BrandTelegramIcon } from "vue-tabler-icons";
import { LogoutIcon } from "vue-tabler-icons";
import { UnlinkIcon } from "vue-tabler-icons";
import { LinkIcon } from "vue-tabler-icons";
import { ChevronUpRightIcon } from "vue-tabler-icons";
import { ethers } from "ethers";

export default {
  name: "TelegramBox",
  mounted() {
    const url = window.location.href;
    if (url.includes('authenticate/telegram')) {
      const params = new URLSearchParams(window.location.search);
      axios.get(`${Config.API}/tokengate/${Config.CHAT}/${this.$store.state.wallet[0]}/authenticate/telegram?${params.toString()}`, {withCredentials: true})
          .then(data => {
            console.log(data);
            window.location.href = '/';
          });
    }
    const tgScript = document.createElement("script");
    tgScript.src = "https://telegram.org/js/telegram-widget.js?15";
    tgScript.setAttribute("data-telegram-login", Config.TELEGRAM_BOT_NAME);
    tgScript.setAttribute("data-size", "large");
    tgScript.setAttribute("data-radius", 0);
    tgScript.setAttribute("data-userpic", false);
    tgScript.setAttribute("data-lang", "en");
    tgScript.setAttribute("data-auth-url", Config.TELEGRAM_BOT_LINK);
    tgScript.async = true;
    document.getElementById("tg-button").append(tgScript);
  },
  data() {
      return {
          telegramLink: Config.TELEGRAM_INVITE_LINK
      }
  },
  components: {
    BrandTelegramIcon,
    LogoutIcon,
    UnlinkIcon,
    ChevronUpRightIcon,
    LinkIcon,
  },
  computed: {
    loggedIn() {
      return (
        this.$store.state.credentials !== null &&
        "telegram_id" in this.$store.state.credentials
      );
    },
    text: function () {
      if (this.loggedIn) {
        return `Logged in as ${this.$store.state.credentials.telegram_username}`;
      } else {
        return "Telegram Log In";
      }
    },
  },
  methods: {
    async logout(){
      await axios.get(`${Config.API}/tokengate/${Config.CHAT}/${this.$store.state.wallet[0]}/logout/telegram`, {withCredentials: true})
      window.location.href = '/';
    },
    async link() {
      let data = {
        account: this.$store.state.wallet[0],
        platform: "telegram",
      };

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      const hash = await ethers.utils.keccak256(this.$store.state.wallet[0])
      const sig = await signer.signMessage(ethers.utils.arrayify(hash))
      const recoveredAddress = ethers.utils.verifyMessage(ethers.utils.arrayify(hash), sig)

      if (recoveredAddress.toLowerCase() != this.$store.state.wallet[0].toLowerCase()) {
        this.$store.commit('modalText', 'You are not the owner of this account!')
        return
      }

      data.signature = sig

      data.telegram_id = this.$store.state.credentials.telegram_id;
      data.telegram_username = this.$store.state.credentials.telegram_username;

      axios
        .post(`${Config.API}/tokengate/${Config.CHAT}/${this.$store.state.wallet[0]}/link`, data, { withCredentials: true })
        .then((response) => {
          let newCredentials = Object.assign({}, this.$store.state.credentials);
          newCredentials.linked_telegram = true;

          this.$store.commit("credentials", newCredentials);
          this.$store.commit('modalText', response.data.message)
        })
        .catch((error) => {
          this.$store.commit('modalText', error.response.data.message)
        });
    },
    async unlink() {
      let data = {
        account: this.$store.state.wallet[0],
        platform: "telegram",
      };

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      const hash = await ethers.utils.keccak256(this.$store.state.wallet[0])
      const sig = await signer.signMessage(ethers.utils.arrayify(hash))
      const recoveredAddress = ethers.utils.verifyMessage(ethers.utils.arrayify(hash), sig)

      if (recoveredAddress.toLowerCase() != this.$store.state.wallet[0].toLowerCase()) {
        this.$store.commit('modalText', 'You are not the owner of this account!')
        return
      }

      data.signature = sig

      data.telegram_id = this.$store.state.credentials.telegram_id;
      data.telegram_username = this.$store.state.credentials.telegram_username;

      axios
        .post(`${Config.API}/tokengate/${Config.CHAT}/${this.$store.state.wallet[0]}/unlink/telegram`, data, { withCredentials: true })
        .then((response) => {

          let newCredentials = Object.assign({}, this.$store.state.credentials);
          newCredentials.linked_telegram = false;

          this.$store.commit("credentials", newCredentials);
          this.$store.commit('modalText', response.data.message)
        })
        .catch((error) => {
          this.$store.commit('modalText', error.response.data.message)
        });
    },
  },
};
</script>