<template>
  <div>
    <p class="text-xl my-2 color-secondary">{{ holderText }}</p>
    <div class="flex">
      <div v-if="eligible" class="w-full lg:w-1/2 lg:pl-5 my-11">
        <telegram-box/>
      </div>
      <div class="w-full lg:w-1/2 lg:pl-5 my-11">
        <wallet-button/>
      </div>
    </div>
  </div>
</template>
<script>
import TelegramBox from '@/components/TelegramBox.vue'
import WalletButton from "@/components/WalletButton.vue";

export default {
  name: "HolderContent",
  components: {
    TelegramBox,
    WalletButton,
  },
  data: function () {
    return {}
  },
  methods: {
    getCookie(id) {
      let value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
      return value ? value[2] : null;
    },
  },
  computed: {
    holderText: function () {
      if (this.$store.state.wallet === null && !this.getCookie('uuid')) {
        return 'Connect your wallet to confirm that you are an Exosama NFT holder.'
      } else if (this.$store.state.eligible === false) {
        return 'You are not an Exosama NFT holder.'
      } else {
        return 'You are an EXO holder. Connect with Telegram or Discord and submit your credentials for accessing the tokengated chats.'
      }
    },
    eligible: function () {
      return (this.$store.state.wallet !== null || this.getCookie('uuid')) && this.$store.state.eligible
    }
  }
}
</script>