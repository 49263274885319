<template>
  <div class="min-h-screen p-4 flex justify-center items-center">
    <div class="w-full max-w-screen-sm">
      <div class="flex justify-between items-center">
        <div>
          <img
              src="@/assets/logo.png"
              class="logo w-full"
              alt="Dremica"
              title="Dremica"
          />
        </div>
      </div>
      <h1 class="font-akira text-h1 mt-20">Tokengated Chat</h1>
      <holder-content/>
    </div>
    <modal></modal>
  </div>
</template>

<script>
import HolderContent from "@/components/HolderContent";
import Modal from "@/components/Modal";

export default {
  name: "App",
  components: {
    HolderContent,
    Modal
  }
};
</script>