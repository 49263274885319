<template>
  <transition name="fade">
    <div class="modal" @click="close()" v-if="$store.state.modalText !== null">
      <div class="flex flex-col justify-center items-center rounded-lg p-8">
        <p class="text-xl my-4">{{ $store.state.modalText }}</p>
        <button class="button wallet" @click="close()">Close</button>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$store.commit('modalText', null)
    },
  },
};
</script>